import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  CardHeader,
  Card,
  CardBody,
} from 'reactstrap';
import classnames from 'classnames';

class PcrPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      pcrPoints: []
    };
  }

  componentDidMount() {
    const pcrPoints = this.props.pcrPoints.mbd;
    this.setState({ pcrPoints });
  }

  getStripedStyle(index) {
    return { background: index % 2 === 0 ? '#f6f6f6' : 'white' };
  }

  changePcrType = (e) => {
    this.setState({
      activeTab: 0,
    });
    this.props.changePcrType(e.target.value);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    let pcrPoints = {
      headers: this.props.pcrPoints.mbd.headers,
      points: [],
      type: '',
      tabs: this.props.pcrPoints.mbd.tabs,
    };
    const bonusPoints = this.props.pcrPoints.bonusPoints;
    const pcrType = this.props.pcrType ? this.props.pcrType.toLowerCase() : '';
    const dealer_type = this.props.dealer_type;
    const pcrExampleData = this.props.pcrExampleData;
    console.log("pcrTypeeee", { pcrType })
    if (this.props.pcrType) {
      if (pcrType === 'multibrand' || pcrType === 'distributor' || pcrType === 'other') {
        pcrPoints = this.props.pcrPoints.mbd;
      } else if (
        pcrType === 'brandshops'
      ) {
        pcrPoints = this.props.pcrPoints.swxw;
      }
      else if (pcrType === 'ptp') {
        pcrPoints = this.props.pcrPoints.ptp;
      }
    }
    return (
      <>
        <div className="PASSENGER-CAR-RADIAL-label">
          {this.props.pcrPoints.type}
        </div>
        <div
          style={{ marginTop: '10px', flexWrap: 'wrap' }}
          className="d-flex-row"
        >
          {((dealer_type === 'am' || dealer_type === 'employee') && !this.props.notByUrl) &&
            !(pcrPoints.year === '2020-21' && this.state.activeTab === 0) && (
              pcrPoints.year === '2025-26' ? (
                <div className="all-pcrtype-wrapp">
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="pcrType"
                        onChange={(e) => this.changePcrType(e)}
                        value="multibrand"
                        checked={
                          !!(pcrType === 'multi brand' || pcrType === 'multibrand')
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>
                      MBO / Distributor / PTP
                    </span>
                  </div>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        name="pcrType"
                        type="radio"
                        onChange={(e) => this.changePcrType(e)}
                        value="brandshops"
                        checked={
                          pcrType === 'brandshops'
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>Brand Stores</span>
                  </div>
                </div>
              ) : (
                <div className="all-pcrtype-wrapp">
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="pcrType"
                        onChange={(e) => this.changePcrType(e)}
                        value="multibrand"
                        checked={
                          !!(pcrType === 'multi brand' || pcrType === 'multibrand')
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>
                      MBO / Distributor
                    </span>
                  </div>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        name="pcrType"
                        type="radio"
                        onChange={(e) => this.changePcrType(e)}
                        value="brandshops"
                        checked={
                          pcrType === 'brandshops'
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>Brand Stores</span>
                  </div>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        name="pcrType"
                        type="radio"
                        onChange={(e) => this.changePcrType(e)}
                        value="ptp"
                        checked={pcrType === 'ptp'}
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>PTP</span>
                  </div>
                </div>
              )
            )}
          {(dealer_type !== 'dealer' && this.props.notByUrl) &&
            !(pcrPoints.year === '2020-21' && this.state.activeTab === 0) && (
              pcrPoints.year === '2025-26' ? (
                <div className="all-pcrtype-wrapp">
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        type="radio"
                        name="pcrType"
                        onChange={(e) => this.changePcrType(e)}
                        value="multibrand"
                        checked={
                          !!(pcrType === 'multi brand' || pcrType === 'multibrand')
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>
                      MBO / Distributor / PTP
                    </span>
                  </div>
                  <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        name="pcrType"
                        type="radio"
                        onChange={(e) => this.changePcrType(e)}
                        value="brandshops"
                        checked={
                          pcrType === 'brandshops'
                        }
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>Brand Stores</span>
                  </div>
                  {/* <div className="d-flex-row pcrtype-wrapp">
                    <label className="custom-checkboax-radio--container">
                      <input
                        name="pcrType"
                        type="radio"
                        onChange={(e) => this.changePcrType(e)}
                        value="ptp"
                        checked={pcrType === 'ptp'}
                      />
                      <span className="checkmark-radio" />
                    </label>
                    <span style={{ marginLeft: '10px' }}>PTP</span>
                  </div> */}
                </div>
              )
                : (
                  <div className="all-pcrtype-wrapp">
                    <div className="d-flex-row pcrtype-wrapp">
                      <label className="custom-checkboax-radio--container">
                        <input
                          type="radio"
                          name="pcrType"
                          onChange={(e) => this.changePcrType(e)}
                          value="multibrand"
                          checked={
                            !!(pcrType === 'multi brand' || pcrType === 'multibrand')
                          }
                        />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        MBO / Distributor
                      </span>
                    </div>
                    <div className="d-flex-row pcrtype-wrapp">
                      <label className="custom-checkboax-radio--container">
                        <input
                          name="pcrType"
                          type="radio"
                          onChange={(e) => this.changePcrType(e)}
                          value="brandshops"
                          checked={
                            pcrType === 'brandshops'
                          }
                        />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}>Brand Stores</span>
                    </div>
                    <div className="d-flex-row pcrtype-wrapp">
                      <label className="custom-checkboax-radio--container">
                        <input
                          name="pcrType"
                          type="radio"
                          onChange={(e) => this.changePcrType(e)}
                          value="ptp"
                          checked={pcrType === 'ptp'}
                        />
                        <span className="checkmark-radio" />
                      </label>
                      <span style={{ marginLeft: '10px' }}>PTP</span>
                    </div>
                  </div>
                )
            )}
          {(this.props.pcrPoints && this.props.pcrPoints.basePoints && <div className="Base-Points-80-Tyre">
            <span className="Base-Points-80-Tyre-label">
              {this.props.pcrPoints.basePoints}
            </span>
          </div>)}
        </div>
        <Card
          tabs="true"
          className="mb-3 about-tab-header"
          style={{ boxShadow: 'none' }}
        >
          <CardHeader className="card-header-tab">
            <Nav style={{ marginLeft: '0' }}>
              {pcrPoints.tabs.map((t, index) => (
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    className={classnames({
                      active: this.state.activeTab === index,
                    })}
                    onClick={() => {
                      this.toggleTab(index);
                    }}
                  >
                    {t}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </CardHeader>
          <CardBody style={{ padding: '0' }}>
            <TabContent activeTab={this.state.activeTab}>
              {pcrPoints.tabs.map((t, index) => {
                return (
                  <TabPane tabId={index}>
                    <br />
                    <Table className="mb-0 table-responsive tbl-about tbl-about">
                      <thead>
                        {!(pcrPoints.year === '2020-21' && t === 'Quarter 1') && (
                          <tr style={{ ...this.getStripedStyle(0) }}>
                            <th
                              colSpan={pcrPoints.headers.length}
                              style={{ textAlign: 'center' }}
                            >
                              {this.props.selectyear === '2020-21'
                                ? 'All Dealers'
                                : pcrPoints.type}
                            </th>
                          </tr>
                        )}
                        <tr>
                          {pcrPoints.headers.map((item) => {
                            return (
                              <th>{item}</th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {(pcrPoints[t] || []).map((row, i) => {
                          return (
                            <tr key={i} style={{ ...this.getStripedStyle(i) }}>
                              {row.map(
                                (item, j) =>
                                  (
                                    ((j === 2 || j === 3) && i === 0) ||
                                    (((j === 1 || j === 0) &&
                                      (pcrPoints.year === '2020-21' || this.props.selectyear === '2020-21') &&
                                      t === 'Quarter 3 & 4') ||
                                      ((j === 1 || j === 0) &&
                                        (pcrPoints.year !== '2019-20' || this.props.selectyear === '2019-20') &&
                                        t === 'Quarter 1 & 4')) ||
                                    !(
                                      ((pcrPoints.year !== '2019-20' || this.props.selectyear !== '2019-20') &&
                                        t === 'Quarter 1 & 4') ||
                                      (pcrPoints.year === '2020-21' || this.props.selectyear === '2020-21') &&
                                      t === 'Quarter 3 & 4'
                                    )) && (
                                    <td
                                      rowSpan={
                                        ((pcrPoints.year === '2020-21' || this.props.selectyear === '2020-21') &&
                                          t === 'Quarter 3 & 4' &&
                                          (j === 2 || j === 3)) || (pcrPoints.year !== '2019-20' || this.props.selectyear !== '2019-20') &&
                                          t === 'Quarter 1 & 4' &&
                                          (j === 2 || j === 3)
                                          ? 7
                                          : 1
                                      }
                                    >
                                      {item}
                                    </td>
                                  )
                              )}
                            </tr>
                          );
                        })}
                        {/* {pcrPoints.points ? pcrPoints.points.map((item , i)=>{
                return(
                  <tr>
                    <td>
                      {item}
                    </td>
                  </tr>
                )
              }) : ''} */}
                      </tbody>
                    </Table>
                  </TabPane>
                );
              })}
            </TabContent>
          </CardBody>
        </Card>

        <div
          className="d-flex-row truck-bus-wrapper"
          style={{ marginRight: '0px', marginLeft: '0px' }}
        >
          {this.props.pcrPoints.note && (
            <div className="truckbus-note two-three-wheeler-multilple-offer-note">
              <span className="Base-Points-80-Tyre-label">
                {typeof this.props.pcrPoints.note === 'object' ? <>
                  {this.props.pcrPoints.note.map(singleNote =>
                    <div className="Base-Points-80-Tyre-label">{singleNote}</div>
                  )}
                </> : <span className="Base-Points-80-Tyre-label">
                  {this.props.pcrPoints.note}
                </span>}
              </span>
            </div>
          )}
        </div>
        <br />
        <br />
        {bonusPoints && bonusPoints.length > 0 ?
          <Table className="mb-0 table-responsive tbl-about tbl-about">
            <thead>
              <tr>
                <th colSpan="3">Bonus Points</th>
              </tr>
            </thead>
            <tbody>
              {bonusPoints.map((row, i) => (
                <tr key={i} style={{ ...this.getStripedStyle(i + 1) }}>
                  <th>{row.label}</th>
                  <th>{row.points}</th>
                  <td style={{ textAlign: 'left' }}>{row.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          : ""}
        {pcrExampleData ? (
          <>
            {pcrExampleData.terms_and_conditions && (
              <div className="terms-and-conditions">
                <div className="About-the-Program mb-2" style={{ fontSize: "16px" }}>T&C: -</div>
                <ol>
                  {(pcrExampleData.terms_and_conditions || []).map((item, index) => {
                    return (
                      <li key={index}>{item}</li>
                    )
                  })}
                </ol>
              </div>
            )}
            {pcrExampleData.example && (
              <>
                {pcrExampleData.example.description && <div className="About-the-Program mb-2" style={{ fontSize: "14px" }}>{pcrExampleData.example.description}</div>}
                {pcrExampleData.example.table && pcrExampleData.example.table.headers && (
                  <Table id="about-program" className="mb-0 table-responsive tbl-about tbl-about">
                    <thead>
                      <tr>
                        {pcrExampleData.example.table.headers.map((header, idx) => (
                          <th key={idx} colSpan={header.colspan}>
                            {header.text}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {(pcrExampleData.example.table.subheaders || []).map((sub, idx) => (
                          <th key={idx}>{sub}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(pcrExampleData.example.table.rows || []).map((row, rowIdx) => (
                        <tr key={rowIdx}>
                          {row.map((cell, cellIdx) => (
                            <td key={cellIdx}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </>
            )}
          </>
        ) : ""}
      </>
    );
  }
}

export default PcrPoints;
